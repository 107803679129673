
form{
    input{
        // border-radius: 20px !important;
        /* height: 40px !important; */
    }
    .geosuggest__input{
        // border-radius: 20px ;
            
    }
}


body {
//     .c-body{
//         background-color: white
// }

//    .card,.c-subheader{
//         background-color: rgb(245 245 250 / 85%)
//     }
    .c-sidebar{
        background: radial-gradient( 100% 100% at 100% 0%, #4f5d73 0%, #4c4f54 100% );
        .c-sidebar-nav-link.c-active{
            background-color: #20a8d8;
        }
    }
    .c-sidebar .c-sidebar-nav-link:hover,.c-sidebar-nav-link.c-active{
        background-color: #20a8d8;
    }

    
}