.upload-pic {
  /* add background image here with size of 500x500 */
  /* background-image: url("https://su.edu.ye/ce/wp-content/uploads/sites/4/2021/03/istockphoto-1156672386-612x612-1.jpg"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  /* position: absolute;

  /* set z index to this image */
  /* z-index: -1;  */
}
